import { Theme2 } from "./theme2/theme2";


function App() {
  return (
    <>
      <Theme2/>
    </>
  );
}

export default App;
