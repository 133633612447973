
import serviceImg from '../img/service.png'
export const Services = ()=>{
    
    return(
        <div className='absolute left-[55%] top-[260%]'>
        <p className='text-xl text-secondaryblue font-normal pb-6'>Services</p>
        
        <div class="grid grid-cols-2 gap-4 pt-6">
           
            {/* {
                item.services.map(
                    (service)=>{
                        return(
                            <div>
                                <img src = {serviceImg}/>
                                <p>{service.name}</p>
                                <p className='text-theme2text'>{service.desc}</p>
                            </div>
                        )
                    }
                )
            } */}
            <div>
                <img src = {serviceImg}/>
                <p>Web Development</p>
                <p className='text-theme2text'>I have hands-on experience in web app development using MERN Stack with FullStack Web Development.</p>
            </div>
            <div>
                <img src = {serviceImg}/>
                <p>Mobile App Development</p>
                <p className='text-theme2text'>I have hands-on experience in mobile app development using Flutter.</p>
            </div>
            <div>
                <img src = {serviceImg}/>
                <p>BlockChain Development</p>
                <p className='text-theme2text'>I have hands-on experience in Daaps using Solidity.</p>
            </div>
            <div>
                <img src = {serviceImg}/>
                <p>IOT Development</p>
                <p className='text-theme2text'>I have hands-on experience with Rasberry Pi, Node MCU, IoT, and Arduino.</p>
            </div>
            
        </div>
    </div>
    )

}