import serviceImg from '../img/degree-hat.png'
export const Education = ()=>{
    return(
        <div className='absolute left-[55%] top-[370%]'>
        <p className='text-xl text-secondaryblue font-normal pb-6'>Education</p>
        
        <div class="grid grid-cols-2 gap-4 pt-6">
            <div>
                <img src = {serviceImg} className='inline' />
                <span className='bg-secondarypink inline rounded-md px-4 py-1 ml-4' >2014-2017</span>
                <p>Advanced Level</p>
                <p className='text-theme2text'>P/L Royal College Physical Stream.</p>
            </div>
            <div>
                <img src = {serviceImg} className='inline'/>
                <span className='bg-secondarypink inline rounded-md px-4 py-1 ml-4' >2019-2023</span>
                <p>Bachelor Degree</p>
                <p className='text-theme2text'>Bachelor Computer Science Degree in Eastern University.</p>
            </div>
            <div>
                <img src = {serviceImg} className='inline'/>
                <span className='bg-secondarypink inline rounded-md px-4 py-1 ml-4' >2018-2019</span>
                <p>NVQ 4 Web Developer</p>
                <p className='text-theme2text'>I got 6 months industriul training for NVQ 4 Course.</p>
            </div>
            <div>
                <img src = {serviceImg} className='inline'/>
                <span className='bg-secondarypink inline rounded-md px-4 py-1 ml-4' >2022-2023</span>
                <p>Node JS and AWS Certification</p>
                <p className='text-theme2text'>AWS Cloud Computing with EC2 Instance.</p>
            </div>
            
        </div>
    </div>
    )

}