
import headerNameImg from '../img/hncontainer.png'
import file from '../../files/Mihiraj\'s Resume.pdf'
export const Home = ()=>{
    
    return(
        <>
            <div className='z-10 absolute left-[35%] top-[25%]'>
                <img src = {headerNameImg} className='w-3/4 h-3/4' alt='homeimg' />
                        
                <div className='absolute top-[25%] left-[12%]'>
                    <p className='text-9xl font-extrabold'>Oshan</p>
                    <p className='text-7xl font-extrabold pl-14'>Mihiraj</p>
                </div>           
            </div>

            <div className='absolute top-[75%] left-[55%] z-10'>
                <p className='text-xl text-secondaryblue font-normal pb-6'>SOFTWARE ENGINEER</p>
                <p className='text-base text-theme2text font-normal /'>
                    I am a final-year Computer Science student at Eastern University. I have one year + of work experience in web apps and mobile app development field. I have developing projects since 2019. So I have hands-on experience with MERN Stack and Flutter.
                </p>
                <button className='bg-secondarypink py-2 px-4 mt-2 rounded-[15px]'>
                    <a href={file} download="Mihiraj's Resume">Download CV</a>
                </button>
            </div>
            
        </>
        
    );
}