import serviceImg from '../img/service.png'
export const Experience = ()=>{
    return(
        <div className='absolute left-[55%] top-[480%]'>
        <p className='text-xl text-secondaryblue font-normal pb-6'>Experience</p>
        
        <div class="grid grid-cols-2 gap-10 ">
            <div>
                
                <div className='relative'>
                    <div className='-rotate-90 absolute -left-14 top-8 text-secondarypink'>2020-2022</div>
                    <p>FullStack Developer</p>
                    <p className='text-theme2text'>I have work one year + work experience in WidDev (PVT) Ltd .</p>
                </div>
            </div>
            <div>
                
                <div className='relative'>
                    <div className='-rotate-90 absolute -left-14 top-8 text-secondarypink'>2020-2022</div>
                    <p>Industrial Training</p>
                    <p className='text-theme2text'>I have 6 Months Industrial Training in BuiltsApps (PVT) Ltd.</p>
                </div>
                
            </div>
            <div>
                
                <div className='relative'>
                    <div className='-rotate-90 absolute -left-14 top-8 text-secondarypink'>2020-2022</div>
                    <p>Web App Development</p>
                    <p className='text-theme2text'>I have work experience with 1 year + work Experience in MERN Stack and Wordpress Web Development .</p>
                </div>
            </div>
            
            <div>
                
                <div className='relative'>
                    <div className='-rotate-90 absolute -left-14 top-8   text-secondarypink'>2020-2022</div>
                    <p>Mobile App Development</p>
                    <p className='text-theme2text'>I have 1 year + work experience with Mobile App Development with Flutter.</p>
                </div>
            </div>
            
        </div>
    </div>
    )

}