import { useContext } from "react"


export const About = ()=>{
    
    return (
        <div className='absolute left-[55%] top-[150%]'>
            <p className='text-xl text-secondaryblue font-normal pb-6'>About Me</p>
            <p className='text-base text-theme2text font-normal '>
            I am a final-year Computer Science student at Eastern University. I have one year + of work experience in web apps and mobile app development field. I have developing projects since 2019. So I have hands-on experience with MERN Stack and Flutter.
            </p>
            <div class="grid grid-cols-2 gap-4 pt-6">
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Age:</div>
                    <div className='text-base text-theme2text font-normal inline'> 25</div>
                </div>
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Residence:</div>
                    <div className='text-base text-theme2text font-normal inline'> Colombo</div>
                </div>
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Freelance:</div>
                    <div className='text-base text-theme2text font-normal inline'> Available</div>
                </div>
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Address:</div>
                    <div className='text-base text-theme2text font-normal inline'> No 56, Batukotuwa</div>
                </div>
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Phone:</div>
                    <div className='text-base text-theme2text font-normal inline'> 076-396-3056</div>
                </div>
                <div>
                    <div className='text-base text-secondaryblue font-normal inline'>Email:</div>
                    <div className='text-base text-theme2text font-normal inline'> omihiraj@gmail.com</div>
                </div>
            </div>
        </div>
    )
}