import { useContext } from "react"

import { Layout } from "./components/layout"
import { NavBar } from "./components/navbar"
export const Theme2 = ()=>{
    
    return (
        <>
            <NavBar />
            <Layout />

        </>
    )
}